$(function () {
  Gmap.init({
    $field: $('#js-gmap-field'),
    $name: $('#js-gmap-name'),
    $address: $('#js-gmap-address'),
    $useName: $('#js-use-name'),
    $shareBtn: $('#js-gmap-shareBtn'),
  });

  Gmap.setShareUrl();
  if (Gmap.checkGetMap()) {
    Gmap.setMap();
  }
});

const Gmap = {

  init (options) {
    this.$field = options.$field;
    this.$name = options.$name;
    this.$address = options.$address;
    this.$useName =  options.$useName;
    this.$shareBtn =  options.$shareBtn;
  },

  // Google Mapを取得するか判定
  checkGetMap() {
    let html = this.$field.html();

    if (!html || html == undefined || html == null) {
      return false;
    }

    return true;
  },

  // Mapの取得に名前を使用するか判定
  checkUseName () {
    let nameFlg = this.$useName.val();

    if (!nameFlg || nameFlg == 0) {
      return false;
    }

    return true;
  },

  // 住所を取得する
  getAddress () {
    let address = this.$address.text();

    if (!this.checkUseName()) {
      return address;
    }

    let name = this.$name.text();

    return  address + ' ' + name;
  },

  // 住所からmap表示
  setMap () {
    let targetAddress = this.getAddress();
    let geocoder = new google.maps.Geocoder();

    geocoder.geocode({
      'address': targetAddress,
      'region': 'jp'
    }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        let field = document.getElementById("js-gmap-field");

        let location = new google.maps.LatLng(
          results[0].geometry.location.lat(),
          results[0].geometry.location.lng()
        );

        let map = new google.maps.Map(field, {
          center: location,
          zoom: 15,
        });

        let marker = new google.maps.Marker({
          position: location,
          map: map,
        });
      }
    });
  },

  // 住所からshareUrl取得
  setShareUrl () {
    let targetAddress = this.getAddress();
    let url = 'https://www.google.com/maps/search/?api=1&query=' + encodeURI(targetAddress);
    this.$shareBtn.attr('href', url);
  },
}
