$(function () {
  Contact.init({
    $pref: $('#js-pref-select'),
    $city: $('#js-city-input'),
    $trigger: $('#js-zip-code-search'),
    $zip: $('#js-zip-code-input'),
  });

  Contact.$trigger.on('click', function (e) {
    e.preventDefault();

    Contact.searchAddress();
  });

  $('#js-recruit-select').on('change', function () {
    Contact.toggleFileUpload();
  });
});

const Contact = {

  init (options) {
    this.$pref = options.$pref;
    this.$city = options.$city;
    this.$trigger = options.$trigger;
    this.$zip = options.$zip;
  },

  searchAddress () {
    this.$trigger.addClass('is-loading');
    let zipCode = this.$zip.val();

    $.ajax({
      url: 'https://zip-cloud.appspot.com/api/search',
      type: 'GET',
      dataType: 'jsonp',
      data:{
        zipcode : zipCode
      }
    }).done((data) => {
      if (data.status !== 200 || !data || data.results === null) {
        this.$trigger.removeClass('is-loading');
        return false;
      }
      let prefectureId = parseInt(data.results[0].prefcode);
      let address = data.results[0].address2 + data.results[0].address3;

      this.$pref.val(prefectureId);
      this.$city.val(address);
      this.$trigger.removeClass('is-loading');
    }).fail((data) => {
      $('.result').html(data);
      console.log(data);
      this.$trigger.removeClass('is-loading');
    });
  },

  toggleFileUpload() {
    let $recruitSelect = $('#js-contact-file');
    let recruitType = $('#js-recruit-select').val();

    if (recruitType === 'career') {
      $recruitSelect.removeClass('is-hidden');

    } else {
      $recruitSelect.addClass('is-hidden');
    }
  },
}
