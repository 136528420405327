getParams = function () {
  let params = {}
  let input = location.search.substring(1).split('&');

  for (let i = 0; i < input.length; i++) {
    let keySearch = input[i].search(/=/);
    let key = '';
    if (keySearch != -1)  {
      key = input[i].slice(0, keySearch);
    }

    let val = input[i].slice(input[i].indexOf('=', 0) + 1);

    if (key != '') {
      params[key] = decodeURI(val);   
    }
  }

  return params; 
}