import Swiper from 'swiper'

function toggleCloseBtn () {
  var hasClass = false

  $('.js-place-select').each(function (index, elm) {
    if ($(elm).find('.location__search-icon-square').hasClass('is-active')) {
      $('.location__search-item-close').addClass('is-active')
      hasClass = true;
      return true
    }
  })

  if (hasClass) {
    return true
  }

  $('.location__search-item-close').removeClass('is-active')
}

$(window).on('load', function () {
  const locationThumbnail = new Swiper('.js-location-thumbnail', {
    slidesPerView: 3,
    spaceBetween: 5,
    navigation: {
      nextEl: '.swiper-thumb-button-next1',
      prevEl: '.swiper-thumb-button-prev1',
    },
  })

  const placeThumbnail = new Swiper('.js-place-thumbnail', {
    slidesPerView: 3,
    spaceBetween: 5,
    navigation: {
      nextEl: '.swiper-thumb-button-next2',
      prevEl: '.swiper-thumb-button-prev2',
    },
  })

  const locationdetailnail = new Swiper('.js-location-swiper', {
    navigation: {
      nextEl: '.swiper-button-next1',
      prevEl: '.swiper-button-prev1',
    },
  })

  const locationmodalnail = new Swiper('.js-location-modal-swiper', {
    thumbs: {
      swiper: locationdetailnail
    },
    navigation: {
      nextEl: '.swiper-modal-button-next1',
      prevEl: '.swiper-modal-button-prev1',
    },
  })

  new Swiper('.js-location-swiper', {
    thumbs: {
      swiper: locationThumbnail
    },
    navigation: {
      nextEl: '.swiper-button-next1',
      prevEl: '.swiper-button-prev1',
    },
  })

  new Swiper('.js-place-swiper', {
    thumbs: {
      swiper: placeThumbnail
    }
  })

  new Swiper('.js-location-swiper', {
    navigation: {
      nextEl: '.swiper-modal-button-next1',
      prevEl: '.swiper-modal-button-prev1',
    },
  })

  new Swiper('.js-location-modal-swiper', {
    thumbs: {
      swiper: locationdetailnail
    },
  })

  $('.swiper-modal-button-next1, .swiper-modal-button-prev1').on('click', function () {
    locationdetailnail.update();
    locationmodalnail.update();
  })

  $('#js-location-detail-image img').on('click', function () {
    locationThumbnail.update();
    locationmodalnail.update();
  })
})

$(function () {
  var params = getParams()

  // 地域選択した場合
  $('.js-area-dropdwon-trigger').on('click', function (e) {
    e.preventDefault()
    $('.js-area-dropdwon-target').toggleClass('is-active')
    $('.js-place-dropdwon-target').removeClass('is-active')
  })

  $('.js-area-select').on('click', function () {
    if ($(this).hasClass('js-area-disable')) {
      return false
    }

    var regionId = $(this).data('regionId')
    var text = $(this).text()
    var $id = '#area-' + regionId

    $('.js-area-input').val(regionId)
    $('.js-area-text').text(text)
    $('.js-area-dropdwon-target').removeClass('is-active')
    $('.js-condition-tag-area').removeClass('is-active')
    $($id).toggleClass('is-active')
  })

  $('.js-condition-tag-area').on('click', function () {
    $('.js-area-input').val(0)
    $('.js-area-text').text('地域を絞る')
    $(this).toggleClass('is-active')
  })

  if (params.region) {
    $('[data-region-id=' + params.region + ']').click()
  }

  // 撮影場所を選択した場合
  $('.js-place-dropdwon-trigger').on('click', function (e) {
    e.preventDefault()
    $('.js-area-dropdwon-target').removeClass('is-active')
    $('.js-place-dropdwon-target').toggleClass('is-active')
  })

  $('.js-place-select').on('click', function () {
    var placeId = $(this).data('placeId')
    var $id = '#place-' + placeId

    $(this).children('.location__search-icon-square').toggleClass('is-active')
    $($id).toggleClass('is-active')

    $('.js-hidden-field').empty();

    $('.js-place-select').each(function (index, element) {
      if ($(element).children('.location__search-icon-square').hasClass('is-active')) {
        var html =  '<input type="hidden" name="place[]" class="js-place-input" value="' + $(element).data('placeId') +'">'
        $('.js-hidden-field').append(html)
      }
    })

    toggleCloseBtn()
  })

  $('.js-condition-tag-place').on('click', function () {
    var selectTarget = $(this).data('ptag')
    var values = [];

    $(this).removeClass('is-active')
    $('[data-place-id=' + selectTarget + ']').children('.location__search-icon-square').removeClass('is-active')

    $('.js-hidden-field').empty();

    $('.js-place-select').each(function (index, element) {
      if ($(element).children('.location__search-icon-square').hasClass('is-active')) {
        var html =  '<input type="hidden" name="place[]" class="js-place-input" value="' + $(element).data('placeId') +'">'
        $('.js-hidden-field').append(html)
      }
    })
  })

  $('.js-location-date-check').on('click', function () {
    var date = $(this).val()
    var id = '#date-' + date

    $(id).toggleClass('is-active')
  })

  $('.js-condition-tag-date').on('click', function () {
    var date = $(this).data('dtag')

    $('[data-date-id=' + date + ']').prop('checked', false)
    $(this).removeClass('is-active')
  })

  $('.location__search-keyword').on('change', function () {
    var word = $(this).val()

    if (word.length == 0) {
      $('.js-condition-tag-keyword').removeClass('is-active')
      return false
    }

    $('.js-condition-tag-keyword').addClass('is-active')
    $('.js-condition-tag-keyword-word').text(word)
  })

  $('.js-condition-tag-keyword').on('click', function () {
    $('.js-condition-tag-keyword').removeClass('is-active')
    $('.location__search-keyword').val('')
  })

  var url = new URL(location)
  var locations = url.searchParams.getAll(decodeURI("place%5B%5D"))

  if (locations) {
    $.each(locations, function (index, value) {
      $('[data-place-id=' + value + ']').click()
    })
  }

  var date = url.searchParams.getAll(decodeURI("date%5B%5D"))

  if (date) {
    $.each(date, function (index, value) {
      var id = '#date-' + value
      $(id).addClass('is-active')
    })
  }

  if (params.keyword) {
    $('.js-condition-tag-keyword').addClass('is-active')
    $('.js-condition-tag-keyword-word').text(params.keyword)
  }

  $('.js-place-close').on('click', function () {
    $('.js-place-dropdwon-target').removeClass('is-active')
  })

  $(document).on('click touchend', function (event) {
    if (!$(event.target).closest('.js-area-dropdwon-target').length) {
      $('.js-area-dropdwon-target').removeClass('is-active')
    }

    if (!$(event.target).closest('.js-place-dropdwon-target').length) {
      $('.js-place-dropdwon-target').removeClass('is-active')
    }
  })
})