$(function () {
  $('.js-open-service-list').on('click', function () {
    $($(this).data('target')).show()
  })

  $('.js-close-service-list').on('click', function () {
    $(this).parents('.insurance-top__services__list').hide()
  })

  $('.js-hoken-dd-trigger').on('click', function () {
    $('.js-hoken-dd-target').toggleClass('is-active');
  })

  $(document).on('click touchend', function (event) {
    if (!$(event.target).closest('.js-hoken-dd-target').length) {
      $('.js-hoken-dd-target').removeClass('is-active')
    }
  })
})
