import slick from 'slick-carousel'

$(function () {
  // slider
  $('.js-home-slick').on('init', function (e, slick) {
    const $dots = $('.slick-dots')
    $dots.find('li').each(function (k, el) {
      $(el).find('button').text('TEST:' + k)
    })
  })

  $('.js-home-slick').slick({
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    speed: 3000,
    autoplaySpeed: 5000,
    autoplay: true,
    cssEase: 'linear',
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false
  })

  $('.js-home-slick-sp').on('init', function (e, slick) {
    const $dots = $('.slick-dots')
    $dots.find('li').each(function (k, el) {
      $(el).find('button').text('TEST:' + k)
    })
  })

  $('.js-home-slick-sp').slick({
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    speed: 3000,
    autoplaySpeed: 5000,
    autoplay: true,
    cssEase: 'linear',
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false
  })

  Home.init({
    $form: $('#js-home-search-form'),
    $types: $('.js-change-search-type')
  })

  Home.$types.on('click', function (e) {
    e.preventDefault()
    Home.changeSearchType($(this))
  })

  $('.js-home-search').on('click', function () {
    Home.search()
  })

  $('.js-change-news-tab').on('click', function (e) {
    e.preventDefault()

    $('.js-change-news-tab').removeClass('is-active')
    $(this).addClass('is-active')

    let category = $(this).data('category');
    let target = '.home__news__list[data-category=' + category + ']';
    $('.home__news__list').removeClass('is-active');
    $(target).addClass('is-active');
  })

  $('.js-home-region-select').on('click', function () {
    const regionId = $(this).data('regionId')
    const text = $(this).text()

    $('.js-home-region-input').val(regionId)
    $('.js-home-region-text').text(text)
    $('.js-dropdwon-target3').removeClass('is-active');
  })
})

const Home = {
  searchBuilding: 1,
  searchLocation: 2,
  searchAction: null,
  $form: null,
  $types: null,

  init (options) {
    this.$form = options.$form
    this.$types = options.$types

    let defaultAction

    this.$types.each(function (key, el) {
      let $el = $(el)

      if ($el.hasClass('is-active')) {
        defaultAction = $el.data('action')
      }
    })

    this.searchAction = defaultAction
  },

  changeSearchType ($type) {
    $('.home__search-icon').toggleClass('is-active')
    this.$types.removeClass('is-active')
    $type.addClass('is-active')

    this.searchAction = $type.data('action')
  },

  search () {
    this.$form.attr('action', this.searchAction).submit()
  },
}
