$(function () {
  Pm.init()

  // お悩みモーダルを表示する(ページ移動時)
  var params = getParams()

  if (params.section) {
    const key = '#' + params.section;

    Pm.showPmModal(key)
  }

  $('.js-dropdwon-trigger1').on('click', function () {
    $('.js-dropdwon-target1').toggleClass('is-active');
    $('.js-dropdwon-target2').removeClass('is-active');
    $('.js-dropdwon-target3').removeClass('is-active');
  })

  $('.js-dropdwon-trigger2').on('click', function () {
    $('.js-dropdwon-target1').removeClass('is-active');
    $('.js-dropdwon-target2').toggleClass('is-active');
    $('.js-dropdwon-target3').removeClass('is-active');
  })

  $('.js-dropdwon-trigger3').on('click', function (e) {
    e.preventDefault()
    $('.js-dropdwon-target1').removeClass('is-active');
    $('.js-dropdwon-target2').removeClass('is-active');
    $('.js-dropdwon-target3').toggleClass('is-active');
  })

  $(document).on('click touchend', function (event) {
    if (!$(event.target).closest('.js-dropdwon-target1').length) {
      $('.js-dropdwon-target1').removeClass('is-active')
    }

    if (!$(event.target).closest('.js-dropdwon-target2').length) {
      $('.js-dropdwon-target2').removeClass('is-active')
    }

    if (!$(event.target).closest('.js-dropdwon-target3').length) {
      $('.js-dropdwon-target3').removeClass('is-active')
    }
  })

  // お悩みモーダルを表示する
  $('.js-open-pm-modal').on('click', function (e) {
    e.preventDefault()

    const key = $(this).data('bodyKey')
    var modalPosition = $('#solutionService').offset().top;

    $("html,body").animate({
      scrollTop: modalPosition
    }, {
      queue: false
    });

    $(this).parents('.js-dropdwon-target1').removeClass('is-active')
    $(this).parents('.js-dropdwon-target2').removeClass('is-active')

    Pm.showPmModal(key)
  })

  // お悩みモーダルを非表示
  $('.js-close-pm-modal').on('click', function () {
    Pm.hidePmModal()
  })
})

const Pm = {
  init () {
  },

  showPmModal (key) {
    $('.js-pm-modal').hide()
    $(key).show()
  },

  hidePmModal () {
    $('.js-pm-modal').hide()
  }
}
