import Swiper from 'swiper'

$(window).on('load', function () {
  const buildingThumbnail = new Swiper('.js-building-thumbnail', {
    slidesPerView: 3,
    spaceBetween: 5,
    navigation: {
      nextEl: '.swiper-thumb-button-next',
      prevEl: '.swiper-thumb-button-prev',
    },
  })

  const buildingdetailnail = new Swiper('.js-building-swiper', {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })

  const buildingmodalnail = new Swiper('.js-modal-swiper', {
    thumbs: {
      swiper: buildingdetailnail
    },
    navigation: {
      nextEl: '.swiper-modal-button-next',
      prevEl: '.swiper-modal-button-prev',
    },
  })

  new Swiper('.js-building-swiper', {
    thumbs: {
      swiper: buildingThumbnail
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })

  new Swiper('.js-building-swiper', {
    navigation: {
      nextEl: '.swiper-modal-button-next',
      prevEl: '.swiper-modal-button-prev',
    },
  })

  new Swiper('.js-modal-swiper', {
    thumbs: {
      swiper: buildingdetailnail
    },
  })

  $('.swiper-modal-button-next, .swiper-modal-button-prev').on('click', function () {
    buildingdetailnail.update();
    buildingmodalnail.update();
  })

  $('#js-detail-image img').on('click', function () {
    buildingThumbnail.update();
    buildingmodalnail.update();
  })
})

$(function () {
  Building.init({
    $child: $('.js-region-child')
  })

  var params = getParams()

  if (!params.region_2 || params.region_2.length !== 0) {
    let parentId = params.region_1
    let selectedChildId = params.region_2

    if (parentId) {
      Building.getChildAreas(parentId, selectedChildId)
    }
  }

  // 地域選択
  $('.js-region-select').on('change', function () {
    let parentId = $(this).val()

    // 地域を選択しなかった場合
    if (!parentId) {
      Building.initChildAreas()
    }

    Building.getChildAreas(parentId, null)
  })

  $('.js-only_empty').on('click', function () {
    Building.toggleEmptyCheck()
  })

  $('.js-only_others').on('click', function () {
    Building.toggleOthersCheck()
  })

  $('.js-only_energy').on('click', function () {
    Building.toggleEnergyCheck()
  })

  $('.js-only_environment').on('click', function () {
    Building.toggleEnvironmentCheck()
  })
});

const Building = {

  init (options) {
    this.$child = options.$child
  },

  toggleEmptyCheck () {
    if ($('#only_empty').val() == 1) {
      $('#only_empty').val(0)
    } else {
      $('#only_empty').val(1)
    }
  },

  toggleOthersCheck () {
    if ($('#only_others').val() == 1) {
      $('#only_others').val(0)
    } else {
      $('#only_others').val(1)
    }
  },

  toggleEnergyCheck () {
    if ($('#only_energy').val() == 1) {
      $('#only_energy').val(0)
    } else {
      $('#only_energy').val(1)
    }
  },

  toggleEnvironmentCheck () {
    if ($('#only_environment').val() == 1) {
      $('#only_environment').val(0)
    } else {
      $('#only_environment').val(1)
    }
  },

  initChildAreas () {
    this.$child.empty()
    this.$child.append('<option value="">-</option>')
  },

  setChildAreas (areas, selectedChildId) {
    this.$child.empty()
    this.$child.append('<option value="">選択して下さい</option>')

    $.each(areas, function (key, value) {
      let option = '<option value="' + key + '"'

      if (key === selectedChildId) {
        option = option + ' selected '
      }

      option = option + '>' + value + '</option>'

      $('.js-region-child').append(option)
    })
  },

  getChildAreas (parentId, selectedChildId) {
    $.ajax({
      url: '/api/get-child-areas',
      type: 'GET',
      data:{
        parentId : parentId
      }
    }).done((data) => {
      if (data.length === 0) {
        this.initChildAreas()
        return false
      }

      this.setChildAreas(data, selectedChildId)
    }).fail((data) => {
      console.log(data)
    })
  }
}
