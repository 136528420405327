$(function () {
  $('.js-navbar-burger').on('click', function (e) {
    e.preventDefault()
    $('.overlay').toggleClass('is-active')
    $('#nav-mobile').toggleClass('is-active')
  })

  $('.overlay').on('click', function () {
    $('.overlay').removeClass('is-active')
    $('#nav-mobile').toggleClass('is-active')
  })

  $('.js-close-mobile-nav').on('click', function (e) {
    e.preventDefault()
    $('.overlay').removeClass('is-active')
    $('#nav-mobile').removeClass('is-active')
  })

  // sp フッターのメニュー開閉
  $('.footer__title').on('click', function () {
    $(this).next('.footer__body').toggle()
  })
})