import slick from 'slick-carousel'

$(window).on('load', function () {
  $('.js-recruit-slick').on('init', function (e, slick) {
    const $dots = $('.slick-dots')
    $dots.find('li').each(function (k, el) {
      $(el).find('button').text('TEST:' + k)
    })
  })

  $('.js-recruit-slick').slick({
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 5,
    centerPadding: '10%',
    centerMode: true,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 5000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerPadding: '5%',
        }
      }
    ]
  })
})

$(function () {
  $('.js-select-job-desc').on('click', function () {
    $('.js-select-job-desc').removeClass('current');
    $(this).addClass('current');

    var targetId = $(this).data('id');

    $('.js-select-job-target').removeClass('is-active');
    $('[data-target-id="' + targetId + '"]').addClass('is-active');
  })
})