$(function () {
  Header.init({
    $service: $('.js-header-service'),
    $building: $('.js-header-building'),
    $company: $('.js-header-company'),
    $recruit: $('.js-header-recruit'),
    $news: $('.js-header-news'),
    $hoverable: $('.js-header-hoverable'),
    $serviceDetail: $('.js-header-service-detail'),
    $buildingDetail: $('.js-header-building-detail'),
    $companyDetail: $('.js-header-company-detail'),
    $recruitDetail: $('.js-header-recruit-detail'),
  });

  Header.$hoverable.on('mouseover', function () {
    let $that = $(this);
    Header.setActiveColor($that);
    Header.openDetail($that);
  });

  Header.$hoverable.on('mouseout click', function () {
    let $that = $(this);
    Header.setInactiveColor($that);
    Header.closeDetail();
  });

  $('#js-header-box-show').on('click', function (e) {
    e.stopPropagation();
    $('#js-header-search-box').toggleClass('header__toggled');
    $('.js-header-search').focus();
  })

  $('#js-header-search-box input').on('click', function (e) {
    e.stopPropagation();
  });

  $('#js-header-search-box, body').on('click', function () {
    $('#js-header-search-box').removeClass('header__toggled');
  })

  $('.js-header-search').on('change', function () {
    $('.js-header-search-form').submit();
  })
});

const Header = {
  init (options) {
    this.$service = options.$service;
    this.$building = options.$building;
    this.$company = options.$company;
    this.$recruit = options.$recruit;
    this.$news = options.$news;
    this.$hoverable = options.$hoverable;
    this.$serviceDetail = options.$serviceDetail;
    this.$buildingDetail = options.$buildingDetail;
    this.$companyDetail = options.$companyDetail;
    this.$recruitDetail = options.$recruitDetail;

    let path = location.pathname.split('/')[1];

    if (!path || path === '' || path === undefined) {
      return false;
    }

    if (path === 'pm' || path === 'location') {
      this.$service.addClass('js-header-active');
      this.setActiveColor(this.$service);
    }

    if (path === 'building') {
      this.$building.addClass('js-header-active');
      this.setActiveColor(this.$building);
    }

    if (path === 'company') {
      this.$company.addClass('js-header-active');
      this.setActiveColor(this.$company);
    }

    if (path === 'recruit') {
      this.$recruit.addClass('js-header-active');
      this.setActiveColor(this.$recruit);
    }

    if (path === 'news') {
      this.$news.addClass('js-header-active');
      this.setActiveColor(this.$news);
    }
  },

  setActiveColor ($that) {
    $that.find('.header__navbar-underline').addClass('header__navbar-underline-active')
    let $arrow = $that.find('.header__navbar-arrow');

    if ($arrow !== undefined) {
      $arrow.addClass('header__navbar-arrow-active');
    }
  },

  setInactiveColor ($that) {
    if ($that.hasClass('js-header-active')) {
      return false;
    }

    $that.find('.header__navbar-underline').removeClass('header__navbar-underline-active')
    let $arrow = $that.find('.header__navbar-arrow');

    if ($arrow !== undefined) {
      $arrow.removeClass('header__navbar-arrow-active');
    }
  },

  openDetail ($that) {
    if ($that.hasClass('js-header-service')) {
      this.$serviceDetail.removeClass('is-hidden');
    }

    if ($that.hasClass('js-header-building')) {
      this.$buildingDetail.removeClass('is-hidden');
    }

    if ($that.hasClass('js-header-company')) {
      this.$companyDetail.removeClass('is-hidden');
    }

    if ($that.hasClass('js-header-recruit')) {
      this.$recruitDetail.removeClass('is-hidden');
    }
  },

  closeDetail () {
    $('.header__hovered').addClass('is-hidden');
  }
}
