require('./bootstrap')
require('./jquery_modal')

require('./common')
require('./util')
require('./home')
require('./header')
require('./header_sp')
require('./pm')
require('./building')
require('./location')
require('./gmap')
require('./contact')
require('./insurance')
require('./recruit')
