function resetArrow () {
  $('.icon-arrow').removeClass('rotate')
}

function removeOpened () {
  $('.js-dropdown-nav-open').removeClass('js-dropdown-nav-opened')
}

function closeNav () {
  $('.js-dropdown-nav-target').addClass('is-hidden')
}

$(function () {
  $('.js-dropdown-nav-open').on('click', function (e) {
    e.preventDefault()

    resetArrow()
    closeNav()

    if ($(this).hasClass('js-dropdown-nav-opened')) {
      removeOpened()
      return false;
    }

    removeOpened()

    $(this).addClass('js-dropdown-nav-opened');
    $(this).find('.icon-arrow').addClass('rotate')

    id = $(this).data('id')
    $('.js-dropdown-nav-target[data-target=' + id + ']').removeClass('is-hidden');
  });
});
